<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="12">
                <v-btn text class="mt-3" @click="$router.push('/')"><v-icon>mdi-chevron-left</v-icon> roody</v-btn>
            </v-col>
            <v-col cols="12">
                <h1 class="text-center">Bikes Admin Panel</h1>
                <v-tabs centered v-model="activeTab">
                    <v-tab>Bestand</v-tab>
                    <v-tab>Bestellungen</v-tab>
                </v-tabs>

                <v-tabs-items v-model="activeTab">
                    <!-- Bestand Tab -->
                    <v-tab-item>
                        <v-row>
                            <v-col class="d-flex justify-center mt-5" cols="12">
                                <v-btn color="primary" @click="openBikeDialog">Neues Bike
                                    hinzufügen</v-btn>
                            </v-col>
                            <v-col cols="12">

                                <v-data-table :headers="bikeHeaders" :items="filteredBikes" item-value="_id"
                                    class="mt-4" dense outlined :items-per-page="-1">
                                    <template v-slot:top>
                                        <v-toolbar flat>
                                            <v-text-field v-model="search" label="Suche" class="mx-4"
                                                clearable></v-text-field>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:item.actions="{ item }">
                                        <v-btn icon color="primary" @click="editBike(item)">
                                            <v-icon color="black">mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>

                            </v-col>
                        </v-row>
                    </v-tab-item>

                    <!-- Bestellungen Tab -->
                    <v-tab-item>
                        <v-row>
                            <!-- Offene Bestellungen -->
                            <v-col cols="12">
                                <h2 class="mt-5">Offene Bestellungen <v-chip>{{ filteredOrders('open').length
                                        }}</v-chip></h2>
                                <v-list dense>
                                    <v-list-item style="border-bottom: 1px solid grey"
                                        v-for="order in filteredOrders('open')" :key="order._id">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $moment(order.date).format("DD.MM.YY HH:mm") }} Uhr
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                Velo: {{
                                                    order.description
                                                }}<br>
                                                Menge: {{
                                                    order.amount }}<br>
                                                User: {{ order.user.vorname }} {{ order.user.nachname }} <br>{{
                                                    order.user.email }}<br> {{
                                                    order.user.telefon }} <br>

                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action class="d-flex flex-row align-center">

                                            <v-btn text @click="updateOrder(order._id, 'canceled')">Stornieren</v-btn>
                                            <v-btn class=" ml-2" color="success"
                                                @click="updateOrder(order._id, 'confirmed')">Bestätigen</v-btn>
                                        </v-list-item-action>

                                    </v-list-item>
                                </v-list>
                            </v-col>

                            <!-- Bestätigte Bestellungen -->
                            <v-col cols="12" class="mt-4">
                                <h2>Bestätigte Bestellungen <v-chip>{{ filteredOrders('confirmed').length
                                        }}</v-chip></h2>
                                <v-list dense>
                                    <v-list-item style="border-bottom: 1px solid grey"
                                        v-for="order in filteredOrders('confirmed')" :key="order._id">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $moment(order.date).format("DD.MM.YY HH:mm") }} Uhr
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                Velo: {{
                                                    order.description
                                                }}<br>
                                                Menge: {{
                                                    order.amount }}<br>
                                                User: {{ order.user.vorname }} {{ order.user.nachname }} <br>{{
                                                    order.user.email }}<br> {{
                                                    order.user.telefon }} <br>

                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action class="d-flex flex-row align-center">
                                            <v-btn class="ml-2" text
                                                @click="updateOrder(order._id, 'canceled')">Stornieren</v-btn>
                                            <v-btn text @click="updateOrder(order._id, 'open')">Öffnen</v-btn>
                                        </v-list-item-action>

                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>

        <!-- Dialog for Adding/Editing Bikes -->
        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ editingBike ? 'Bike Bearbeiten' : 'Neues Bike Hinzufügen' }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form">
                        <v-text-field v-model="bikeForm.imageFileName" label="Bild Name" required></v-text-field>
                        <v-text-field v-model="bikeForm.description" label="Beschreibung" required></v-text-field>
                        <v-text-field v-model="bikeForm.originalPrice" label="UVP" type="number"
                            required></v-text-field>
                        <v-text-field v-model="bikeForm.quantity" label="Lagerbestand" type="number"
                            required></v-text-field>
                        <v-text-field v-model="bikeForm.size" label="Grösse"></v-text-field>
                        <v-text-field v-model="bikeForm.discountPrice" label="Aktionspreis"
                            type="number"></v-text-field>
                        <v-text-field v-model="bikeForm.suitableHeight"
                            label="Geeignet für Körpergrösse"></v-text-field>
                        <v-text-field v-model="bikeForm.category" label="Kategorie"></v-text-field>
                        <v-text-field v-model="bikeForm.brandName" label="Markenname"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn text @click="closeDialog">Abbrechen</v-btn>
                    <v-btn color="primary" text @click="saveBike">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import api from "@/api";

export default {
    data() {
        return {
            
            activeTab: 0,
            bikes: [],
            allOrders: [],
            bikeHeaders: [
                { text: "Beschreibung", value: "description" },
                { text: "Lagerbestand", value: "quantity" },
                { text: "Verfügbar (Bestand - Bestellungen)", value: "availableStock" },
                { text: "Aktionen", value: "actions", sortable: false },
            ],
            dialog: false,
            editingBike: null,
            bikeForm: {
                description: "",
                originalPrice: 0,
                quantity: 0,
                size: "",
                discountPrice: 0,
                suitableHeight: "",
                category: "",
                brandName: "",
            },
            search: "",
        };
    },
    computed: {
        filteredBikes() {
            if (!this.search) {
                return this.bikes;
            }
            const searchLower = this.search.toLowerCase();
            return this.bikes.filter((bike) => {
                return (
                    bike.description.toLowerCase().includes(searchLower) ||
                    bike.brandName?.toLowerCase().includes(searchLower) ||
                    bike.category?.toLowerCase().includes(searchLower)
                );
            });
        },
    },

    methods: {
        async fetchBikes() {
            const response = await api.fetchBikes();
            this.bikes = response.data.map((bike) => ({
                ...bike,
                availableStock: bike.quantity - bike.orders.reduce((sum, order) => sum + order.amount, 0),
            }));
        },
        async fetchAllOrders() {
            const response = await api.fetchAllOrders();
            this.allOrders = response.data.flatMap((bike) =>
                bike.orders.map((order) => ({
                    ...order,
                    description: bike.description,
                }))
            );
        },
        filteredOrders(status) {
            return this.allOrders.filter((order) => order.status === status);
        },
        openBikeDialog() {
            this.dialog = true;
            this.editingBike = null;
            this.resetBikeForm();
        },
        editBike(bike) {
            this.dialog = true;
            this.editingBike = bike;
            Object.assign(this.bikeForm, bike);
        },
        closeDialog() {
            this.dialog = false;
            this.resetBikeForm();
        },
        async saveBike() {
            if (this.editingBike) {
                await api.updateBike(this.editingBike._id, this.bikeForm);
            } else {
                await api.addBike(this.bikeForm);
            }
            this.fetchBikes();
            this.closeDialog();
        },
        resetBikeForm() {
            this.bikeForm = {
                description: "",
                originalPrice: 0,
                quantity: 0,
                size: "",
                discountPrice: 0,
                suitableHeight: "",
                category: "",
                brandName: "",
            };
        },
        async updateOrder(orderId, status) {
            try {
                await api.updateOrder(orderId, { status });
                alert(`Order ${status} successfully.`);
                this.fetchAllOrders();
            } catch (error) {
                alert("Error updating order.");
            }
        },
    },
    created() {
        this.fetchBikes();
        this.fetchAllOrders();
    },
};
</script>
